<template>
  <div class="share-file-list-container">
    <div v-if="loading">Please wait for a few seconds...</div>
    <div v-else-if="grantSegmentList.length">
      <div 
        :class="{
          'share-file-card': true,
          'share-file-card--pass': item.state === 'pass',
          'share-file-card--reject': item.state === 'reject',
          'share-file-card--untreated': item.state === 'untreated'
        }"
        v-for="item in grantSegmentList" :key="item.id"
        @click="handleClick(item)"
      >
        <div class="share-file-card-icon">
          <Icon size="40" type="md-checkmark" v-if="item.state === 'pass'"/>
          <Icon size="40" type="md-close" v-if="item.state === 'reject'"/>
          <Icon size="40" type="md-share-alt" v-if="item.state === 'untreated'"/>
        </div>
        <div class="share-file-card-body">
          <div class="share-file-card-body-content">
            {{getContent(item.state)}}
          </div>
          <div class="share-file-card-body-info">
            <span>{{item.regtime}}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>There is no sharing file for you now.</div>
  </div>
</template>

<script>
export default { 
  data () {
    return {
      loading: true,
      projectId: '',
      grantSegmentList: []
    }
  },
  mounted () {
    this.projectId = this.$route.query.id
    this.$store.dispatch('getAllGrantSegment', {
      projectId: this.projectId,
      userId: this.$cookies.get('user_id'),
      state: ''
    }).then(res => {
      this.loading = false
      this.grantSegmentList = res.data.data.items
    })
  },
  methods: {
    getContent(state) {
      if (state === 'pass') return 'This file sharing has been passed.'
      if (state === 'untreated') return 'Please check this file sharing.'
      if (state === 'reject') return 'This file sharing has been rejected.'
    },
    handleClick(item) {
      if (item.state !== 'untreated') return
      this.$router.push('checkGrantSegment?id=' + item.id)
    }
  }
}
</script>

<style lang="less">
.share-file-list-container {
  padding: 20px;
  text-align: left;
  .share-file-card {
    border-radius: 4px;
    height: 100px;
    margin: 1rem;
    padding: 1rem 2rem;
    display: flex;
    &--pass {
      border: 1px solid #24b624;
      color: #24b624;
    }
    &--untreated {
      border: 1px solid #111;
      color: #111;
      cursor: pointer;
    }
    &--reject {
      border: 1px solid red;
      color: red;
    }
    &-icon {
      flex: 0 0 60px;
    }
  }
}
</style>